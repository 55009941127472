import styles from "./index.module.scss";
import Footer from "@/components/footer";
import { SetStateAction, useEffect, useRef, useState } from "react";
import Introduction from "@/components/introduction";
import { EffectFade, EffectCoverflow, Pagination, Navigation, Autoplay, } from 'swiper/modules';
import HomeSwiper from "@/components/swiper";
import { Swiper as reactSwiper } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useIntl } from "react-intl";
import apiService from '@/hooks/apiService';
import Link from "next/link";
import HeartTest from "@/components/popUp/heartTest";
import Image from 'next/image';
import IconLine from '~/public/img/icon_line.png';
import IconTg from '~/public/img/icon_tg.png';
import IconDiscord from '~/public/img/icon_discord.png';
import IconQQ from '~/public/img/icon_qq.png';
import TestIcon from '~/public/img/testIcon.png';
import Title1 from '~/public/img/title1.png';
import Title2 from '~/public/img/title2.png';
import Title3 from '~/public/img/title3.png';
import GameTitleImg from '~/public/img/gameTitleImg.png';
import IconAnnouncement from '~/public/img/icon-announcement.png';
import NextStep from '~/public/img/nextStep.png';
import eventBus from "@/utils/eventBus";

interface ChildComponentProps {
  sendDataToParent: (value: boolean) => void;
}
const Home: React.FC<ChildComponentProps> = ({ sendDataToParent }) => {
  const [scrollDistance, setScrollDistance] = useState(0);
  const [screenWidth, setScreenWidth] = useState<number | null>(null);
  const [popUpHeartTest, setPopUpHeartTest] = useState(false); //心里测试
  const intl = useIntl();
  const locale = intl.locale; // 获取当前语言环境
  //获取监听滚动条
  const handleScroll = () => {
    const distance = document.documentElement.scrollTop || document.body.scrollTop;
    setScrollDistance(distance);
  };
  const handleClick = () => {
    sendDataToParent(true);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth) {
        setScreenWidth(window.innerWidth);
      }
    };
    if (typeof window !== 'undefined') {
      setScreenWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);
  const [activeSlide, setActiveSlide] = useState(0);
  useEffect(() => {
    reactSwiper.use([EffectFade, EffectCoverflow, Pagination, Navigation, Autoplay]);
    reactSwiperRef.current = new reactSwiper('.swiper-container', {
      fadeEffect: { crossFade: true },
      speed: 1500,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      navigation: true,
      on: {
        slideChange: (swiper: { realIndex: SetStateAction<number>; }) => {
          setActiveSlide(swiper.realIndex);
        }
      }
    });
  }, []);

  const reactSwiperRef = useRef<reactSwiper>(null!);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoRef1 = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const togglePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };
  const handleToggleMute = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(videoRef.current.muted);
    }
  };
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.autoplay = true;
      videoRef.current.loop = true;
      videoRef.current.playsInline = true;
      videoRef.current.play();
      setIsPlaying(videoRef.current.paused ? false : true);
    }
    if (videoRef1.current) {
      videoRef1.current.muted = true;
      videoRef1.current.autoplay = true;
      videoRef1.current.loop = true;
      videoRef1.current.playsInline = true;
      videoRef1.current.play();
    }
  }, []);


  const BeiJingTimeDate = (params: any) => {
    const date = new Date(params);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`
  }
  const [list, setList] = useState([{ contentZHHans: '', contentZHHant: '', anTime: '', titleZHHans: '', titleZHHant: '' }]);
  useEffect(() => {
    getMents()
  }, []);
  const getMents = async () => {
    const params = {
      category: "",
      page: 1,
      pageSize: 4
    };
    const result = await apiService.get('/api/app/an/ments', params);
    if (result) {
      setList(result)
    }
  };


  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const targetDate = new Date('2024-12-18T12:00:00+08:00');
    let countdownInterval: NodeJS.Timeout;
    const updateCountdown = () => {
      const currentDate = new Date();
      const timeDifference = targetDate.getTime() - currentDate.getTime();

      if (timeDifference <= 0) {
        clearInterval(countdownInterval);
      } else {
        setDays(Math.floor(timeDifference / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        setMinutes(Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)));
        setSeconds(Math.floor((timeDifference % (1000 * 60)) / 1000));
      }
    }

    updateCountdown();
    countdownInterval = setInterval(updateCountdown, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  useEffect(() => {
    const handleHeartTest = (data: boolean) => {
      setPopUpHeartTest(data);
    };

    eventBus.on('heartTest', handleHeartTest);

    return () => {
      eventBus.off('heartTest', handleHeartTest);
    };
  }, []);

  return (
    <>
      <div className={styles.scrollingLayer}>
        <ul className={styles.contactDetails}>
          {/* <a href="https://discord.gg/Psv6B7tr2R" target="_blank">
            <li><Image src={IconDiscord} alt="" /></li>
          </a> */}
          <a href="http://lin.ee/g0Of3BO" target="_blank">
            <li><Image src={IconLine} alt="" /></li>
          </a>
          <a href="https://t.me/haiwang_game" target="_blank">
            <li><Image src={IconTg} alt="" /></li>
          </a>
          <li>
            <Image src={IconQQ} alt="" />
            <div className={styles.hovPack}>
              {/* <a href="https://qm.qq.com/q/Mwfu6iBn2K" target="_blank">
                  <div>海王传奇1</div>
                </a> */}
              {/* <a href="https://qm.qq.com/q/ZGTuBYgRKG" target="_blank">
                  <div>海王传奇2</div>
                </a> */}
              <a href="https://qm.qq.com/q/S3lpRywW8C" target="_blank">
                <div>海王传奇3</div>
              </a>
              <a href="https://qm.qq.com/q/yQ6IsOtVxC" target="_blank">
                <div>海王传奇4</div>
              </a>
              <a href="https://qm.qq.com/q/HSuy2Dng6i" target="_blank">
                <div>海王传奇5</div>
              </a>
              <a href="https://qm.qq.com/q/YDlQI3n5ks" target="_blank">
                <div>海王传奇6</div>
              </a>
            </div>
          </li>
        </ul>
        <div className={styles.pagePack1} >
          {/* <video ref={videoRef1} className={styles.homeBg} src={
              locale == 'zh-CN' ? (screenWidth! <= 600 ? "/img/homeShipinH5Cn.mp4" : "/img/homeShipinCn.mp4") : (screenWidth! <= 600 ? "/img/homeShipinH5.mp4" : "/img/homeShipin.mp4")
            }></video>  */}
          <div className={'swiper-container'}>
            <div className='swiper-wrapper' style={{ fontSize: '0', zIndex: '0', width: '100%' }}>
              {['/img/homeShipinH506.jpg','/img/homeShipinH5.jpg', '/img/homeShipinH502.jpg', '/img/homeShipinH503.jpg', '/img/homeShipinH504.jpg'].map((h5Img, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img src={h5Img.replace('H5', '')} className={styles.swiperPc} />
                    <img src={h5Img} className={styles.swiperH5} />
                  </SwiperSlide>
                );
              })}
            </div>
          </div>
          {/* <div className={styles.countdownPack}>
            <h2>{intl.formatMessage({ id: "gameDown1" })}</h2>
            <div className={styles.countdown}>
              {days}<em>天</em>{hours}<em>时</em>{minutes}<em>分</em>{seconds}<em>秒</em>
            </div>
            <div className={styles.title}>{intl.formatMessage({ id: "gameDown2" })}</div>
          </div> */}
          {/* <div className={styles.equipmentPack}>
            <Image src={TestIcon} alt="" onClick={() => { setPopUpHeartTest(true) }} />
          </div> */}
        </div>
        {/* <img src="/img/systemBg.png" alt="" className={styles.systemBg} /> */}
        <div className={styles.pagePack2}>
          <div className={styles.contentPack + " " + styles.contentPack1}>
            <div className={styles.introductionPack}>
              <div className={styles.titlePack}>
                <Image src={Title1} alt="" />
              </div>
              <div className={styles.pack}>
                <Introduction></Introduction>
              </div>
            </div>
          </div>
          <div className={styles.contentPack + " " + styles.contentPack2}>
            <div className={styles.swiper}>
              <div className={styles.titlePack}>
                <Image src={Title3} alt="" />
              </div>
              <div className={styles.pack}>
                <HomeSwiper></HomeSwiper>
              </div>
            </div>
          </div>
          <div className={styles.contentPack + " " + styles.contentPack3} id="div3">
            <div className={styles.announcement}>
              <div className={styles.titlePack}>
                <Image src={Title2} alt="" />
              </div>
              <div className={styles.announcementPack}>
                <div className={styles.announcementL}>
                  <Image src={GameTitleImg} alt="" className={styles.titleImg} />
                  <ul className={styles.titleList}>
                    <Link href={'/announcement'}>
                      {list.map((item, index) => <li key={index}>
                        <Image src={IconAnnouncement} alt="" />
                        <div key={index}>
                          <p className={styles.time}>{BeiJingTimeDate(item.anTime)}</p>
                          <p className={styles.content}>{locale == 'zh-CN' ? item.contentZHHans : item.contentZHHant}</p>
                        </div>
                        <Image src={NextStep} alt="" />
                      </li>)}
                    </Link>
                  </ul>
                </div>
                <div className={styles.announcementR}>
                  <video ref={videoRef} className={styles.video}>
                    <source src="https://v.pussygame2.com/hw.mp4" type="video/mp4" />
                  </video>
                </div>
                {/* <Notify></Notify> */}
              </div>
            </div>
          </div>
          <div className={styles.contentPack + " " + styles.contentPack4}>
            <div className={styles.footer}>
              <div className={styles.footerL}>
                <img src={locale == 'zh-CN' ? "/img/footerLoginCn.png" : "/img/footerLogin.png"} alt="" />
              </div>
              {
                locale == 'zh-CN' ? (
                  <div className={styles.footerR}>
                    <div className={styles.textPack}><span>游戏类型</span><div></div><span>恋爱放置类</span></div>
                    <div className={styles.textPack}><span>支持平台</span><div></div><span className={styles.androdPack}><img src="/img/apple.png" alt="" />iOS</span><span className={styles.androdPack}><img src="/img/androidIcon.png" alt="" />Android</span><span><img src="/img/webIcon.png" alt="" />Web</span></div>
                    <div className={styles.textPack}><span>设备规格</span><div></div></div>
                    <p>
                      最低配置：<br />
                      安卓系统：安卓6.0以上<br />
                      处理器：高通骁龙600<br />
                      内存：1 GB RAM<br />
                      存储空间：需要 2 GB 可用空间<br />
                      <br />
                      iOS系统：iOS 10.0 以上<br />
                      手机机型： iPhone Ｘ 以上版本<br />
                      iPad机型：配备 A13 及以上处理器<br />
                      RAM：4GB以上<br />
                      <br />
                      iOS网页版：iOS 10.0 以上<br />
                      1.iPhone 10以上或iOS 17以上的软件版本<br />
                      2.网页版目前无法支持随电脑屏幕调整尺寸，建议使用手机进行游玩体验<br />
                    </p>
                  </div>
                ) : (
                  <div className={styles.footerR}>
                    <div className={styles.textPack}><span>遊戲類型</span><div></div><span>戀愛放置類</span></div>
                    <div className={styles.textPack}><span>支援規格</span><div></div><span className={styles.androdPack}><img src="/img/apple.png" alt="" />iOS</span><span className={styles.androdPack}><img src="/img/androidIcon.png" alt="" />Android</span><span><img src="/img/webIcon.png" alt="" />Web</span></div>
                    <div className={styles.textPack}><span>設備規格</span><div></div></div>
                    <p>
                      最低配備：<br />
                      安卓系統：安卓6.0以上<br />
                      處理器：高通驍龍600<br />
                      記憶體：1 GB RAM<br />
                      儲存空間：需要 2 GB 可用空間<br />
                      <br />
                      iOS系统：iOS 10.0 以上<br />
                      手機機型： iPhone Ｘ 以上機種<br />
                      iPad機型：搭載 A13 及以上處理器<br />
                      RAM：4GB以上<br />
                      <br />
                      iOS網頁版：<br />
                      1.iPhone 10以上或iOS 17以上的軟體版本<br />
                      2.網頁版目前無法支援隨電腦螢幕調整尺寸，建議使用手機進行遊玩體驗<br />
                    </p>
                  </div>
                )
              }
            </div>
            <Footer ></Footer>
          </div>
        </div>
      </div>
      {popUpHeartTest && <HeartTest setPopUpHeartTest={setPopUpHeartTest}></HeartTest>}
    </>
  );
}
export default Home
