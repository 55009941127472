import "@/styles/globals.css";
import type { AppProps } from "next/app";
import { useEffect, useState } from "react";
import Cookie from 'js-cookie';
import LocaleData from '../../public/locales/zh-CN.json'
import { IntlProvider } from "react-intl";
import Header from "@/components/header";
import Footer from "@/components/footer";
import VerificationPopUp from "@/components/verificationPopUp";
import R18PopUp from "@/components/popUp/R-18PopUp";
import ActivityPopUp from "@/components/popUp/activityPopUp";
import { useRouter } from "next/router";


export default function App({ Component, pageProps }: AppProps) {
  const [locale, setLocale] = useState('zh-CN'); // 可以从 Cookie 或浏览器设置中动态获取
  const [messages, setMessages] = useState(LocaleData);
  const [popUpSwitch, setPopUpSwitch] = useState(false); //弹窗开关
  const [popUpR18, setPopUpR18] = useState(false); //18禁弹窗开关
  const [activity, setActivity] = useState(true); //活动公告弹窗开关
  const router = useRouter();
  const [dc, setDc] = useState('');
  useEffect(() => {
    async function loadLocaleData() {
      const lang = Cookie.get('locale') || 'zh-CN';
      const localeData = await import(`../../public/locales/${lang}.json`);
      setLocale(lang);
      setMessages(localeData.default);
    }
    loadLocaleData();
  }, []);
  useEffect(() => {
    if (router.query.dc) {
      setDc(String(router.query.dc));
    }
  }, [router.query.dc]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      console.log('路由变化:', url, 'dc:', dc);
      
      // 确保有 dc 值且新路由不包含 dc
      if (!dc) return;
      
      // 创建完整的URL对象
      const fullUrl = new URL(url, window.location.origin);
      
      // 如果已经有 dc 参数，则不处理
      if (fullUrl.searchParams.has('dc')) return;
      
      // 添加 dc 参数
      fullUrl.searchParams.set('dc', dc);
      
      // 构造新的相对URL路径
      const newPath = fullUrl.pathname + fullUrl.search;
      
      console.log('准备跳转到:', newPath);
      
      // 使用 push 而不是 replace
      router.push(newPath, undefined, { shallow: true })
        .catch(err => console.error('路由更新失败:', err));
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, dc]);
  const changeLocale = async (newLocale: any) => {
    const localeData = await import(`../../public/locales/${newLocale}.json`);
    setLocale(newLocale);
    setMessages(localeData.default);
    Cookie.set('locale', newLocale, { expires: 365 });
  };
  return <IntlProvider locale={locale} messages={messages}>
    <Header sendDataToParent={setPopUpSwitch} changeLocale={changeLocale}></Header>
    {popUpSwitch && <VerificationPopUp sendDataToParent={setPopUpSwitch}></VerificationPopUp>}
    {popUpR18 && <R18PopUp setPopUpR18={setPopUpR18}></R18PopUp>}
    {/* {activity && <ActivityPopUp setActivity={setActivity}></ActivityPopUp>} */}
    <Component sendDataToParent={setPopUpSwitch} {...pageProps} />
    {/* <Footer ></Footer> */}
  </IntlProvider>;
}
